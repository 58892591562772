.container{ position: relative; padding-top: 42.5%; overflow: hidden; background: #eee;}
.container.size_large{ padding-top: 58.4%;}
.container > div:first-child { position: absolute; top: 0; left: 0; }
.container > div > div img { width: 100%; height: 100%;  object-fit: cover;}
.item > a { display: block; }
.nav{width: 40px;height: 40px;background: rgba(255,255,255,0.9);border: none;padding: 0;font-size: 0;position: absolute;top: 50%;margin-top: -20px;z-index: 100; cursor:pointer;opacity:0; visibility:hidden; transition: opacity .3s, visibility .3s}
.nav:before {font-family: 'jt-font'; font-size:17px; font-weight:normal;display:block;}

.prev {left:0;border-radius: 0 30px 30px 0}
.prev:before{content:'\e909'}
.next {right:0;border-radius: 30px 0 0 30px}
.next:before{content:'\e90a'}

:global(.desktop) .container:hover .nav {opacity:1;visibility:visible;}

.paging_info {cursor:pointer; line-height:1;padding: 5px 11px 6px 11px;font-size:10px;letter-spacing:var(--letter-spacing);color:#fff;background:#222222;background:rgba(34, 34, 34, .7);border-radius: 30px 0 0 30px;position:absolute;right: 0;bottom: 17px;z-index:100;}
.paging_num {display:inline-block; vertical-align:middle; }
.paging_num,
.paging_view_all span {font-weight: 700;}
.paging_view_all {margin-left:9px; padding-left:6px;font-size: 10px;color:#fff;letter-spacing: var(--letter-spacing);display:inline-block;vertical-align:middle; position:relative;}
.paging_view_all:before {width:1px; height:8px; background:#aaa; content:''; display:block; position:absolute; left:-1px; top:1px; }

:global(.android) .paging_info { padding-top: 6px; padding-bottom: 6px;}
:global(.ios) .paging_info { padding-top: 8px; padding-bottom: 7px;}
:global(.ios) .paging_view_all:before {top:0.5px;}

@media(min-width:1023px){

    .paging_info {font-size:11px;}
    .paging_view_all {font-size:12px;top: -1px}
    .size_large .paging_view_all{top: 0px} /* TODO Find why need this to fix text vertical align */
    .paging_num,
    .paging_view_all span {font-weight: 400;}
    .paging_view_all:before {top:2px; height:9px;}
    .paging_info {padding: 6px 14px 8px 17px; bottom: 20px;}
    :global(.ios) .paging_num {margin-top:-3px;}
    :global(.ios) .paging_view_all:before {top:2px;}

}
